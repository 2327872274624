import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { UiService } from 'app/shared/services/ui.service';
import { of, switchMap } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
  const router = inject(Router);
  const uiService = inject(UiService);

  return inject(AuthService)
    .checkUserSession()
    .pipe(
      switchMap((authenticated) => {
        const title = route.data['title'];
        const breadCrumbs = route.data['breadCrumbs'];

        uiService.breadCrumbs = [];

        if (title) {
          uiService.pageTitle = title;
        }

        if (breadCrumbs) {
          uiService.breadCrumbs = breadCrumbs;
        }

        // if (!authenticated) {
        //   const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
        //   const urlTree = router.parseUrl(`sign-in?${redirectURL}`);

        //   return of(urlTree);
        // }

        // Allow the access
        return of(true);
      })
    );
};
