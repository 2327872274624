<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img
      class="w-7 h-7 rounded-full"
      *ngIf="showAvatar && user.avatar"
      [src]="user.avatar"
    />
    <mat-icon
      *ngIf="!showAvatar || !user.avatar"
      [svgIcon]="'heroicons_outline:user-circle'"
    ></mat-icon>
    <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full"></span>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>{{ user.firstName }} {{ user.lastName }}</span>
      <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon
      [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"
    ></mat-icon>
    <span>{{ 'user.profile.menu.sign-out' | transloco }}</span>
  </button>
</mat-menu>
